import { Injectable } from '@angular/core';
import { AccountInfo } from '@azure/msal-browser';
import { createStore, select, withProps } from '@ngneat/elf';
import { localStorageStrategy, persistState } from '@ngneat/elf-persist-state';
import { TenantKey } from '../../shared/models/tenant';
import { gmsEnvironment } from '../environments/environment';
import { UserMeDto, UserRoles } from './api/user.model';
import { RequestPermissionsType } from './api/configuration.model';
import { IStore, IStoreProps } from '../../shared/services/store.interface';
import { ApplicationType } from '../../shared/models/application-type';
import { startWith } from 'rxjs';

const gmsStoreKey = 'gms.store';
interface GmsProps extends IStoreProps {
  user?: AccountInfo,
  gmsUser?: UserMeDto;
  permissions?: { [key: string]: RequestPermissionsType };
}

const gmsStore = createStore(
    { name: 'user' },
    withProps<GmsProps>({})
);

//save in local storage
export const persist = persistState(gmsStore, {
    key: gmsStoreKey,
    storage: localStorageStrategy,
});

gmsStore.subscribe((state) => console.log(state));
//persist.initialized$.subscribe(console.log);

@Injectable({ providedIn: 'root' })
export class GmsStore implements IStore {

    store$ = gmsStore;

    user$ = gmsStore.pipe(select((state) => state.user));
    gmsUser$ = gmsStore.pipe(
        select((state) => state.gmsUser ? new UserMeDto(state.gmsUser) : undefined),
        //startWith(this.current.gmsUser ? new UserMeDto(this.current.gmsUser) : undefined)
    );

    constructor() {
    }

    get current(): GmsProps {
        return gmsStore.getValue();
    }

  get applicationType(): ApplicationType {
    return ApplicationType.Gms;
  }

    setUser(user: AccountInfo | undefined) {
        gmsStore.update((state) => ({
            ...state,
            user
        }));
    }

    setGmsUser(gmsUser: UserMeDto | undefined) {
      gmsStore.update((state) => ({
        ...state,
        gmsUser
      }));
    }

    setPermissions(permissions: { [key: string]: RequestPermissionsType } | undefined) {
      gmsStore.update((state) => ({
        ...state,
        permissions
      }));
    }

    cleanUser() {
        console.log("GmsStore.clean")
        //leave token
        this.setUser(undefined);
        this.setGmsUser(undefined);
    }
}
