import { BrowserCacheLocation, Configuration, LogLevel } from "@azure/msal-browser";
import { TenantKey } from "../../shared/models/tenant";
import { gmsEnvironment } from "../environments/environment";
import { IConfig } from "../../shared/services/config.interface";
import { Injectable } from "@angular/core";
import { PortalStore } from "../../portal/services/portal.store";

@Injectable({ providedIn: 'root' })
export class GmsConfigService implements IConfig {

  private _tenant?: TenantKey;

  constructor(store: PortalStore) {
    this._tenant = store.current.tenant;
    store.tenant$.subscribe(tenant => this.setTenant(tenant));
  }

  public setTenant(tenant?: TenantKey) {
    this._tenant = tenant;
  }

  get tenant(): TenantKey | undefined { return this._tenant; }
  get apiUrl(): string { return this._tenant ? gmsEnvironment.api[this._tenant!]!.url : ''; }
  get msalAuthority(): string { return this._tenant ? gmsEnvironment.api[this._tenant!]!.authority : ''; }

  getMsalScopes(): string[] {
    return this._tenant ? gmsEnvironment.api[this._tenant!]!.scopes : [];
  }
  //["https://management.azure.com/.default", ...

  hasMsalConfig(tenant: TenantKey | null = null): boolean {
    if (tenant) {
      return !!gmsEnvironment.api[tenant] && !!gmsEnvironment.api[tenant!]?.url;
    }
    return !!this._tenant && !!gmsEnvironment.api[this._tenant!] && !!gmsEnvironment.api[this._tenant!]?.url;
  }

  getMsalConfig(): Configuration {
    return this._tenant ? {
      auth: {
        clientId: gmsEnvironment.api[this._tenant!]!.clientId,
        authority: gmsEnvironment.api[this._tenant!]!.authority,
        redirectUri: window.location.origin
      },
      cache: {
        cacheLocation: BrowserCacheLocation.LocalStorage
      },
      system: {
        allowNativeBroker: false, // Disables WAM Broker
        allowRedirectInIframe: true,
        loggerOptions: {
          loggerCallback: (logLevel: LogLevel, message: string) => console.log(message),
          logLevel: LogLevel.Warning,
          piiLoggingEnabled: false
        }
      }
    } : {} as Configuration;
  }
}
