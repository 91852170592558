import { v4 as uuidv4 } from 'uuid';

export enum ProjectPermissionLevel {
  Unknown = "Unknown",
  ProjectMember = "Project Member",
  ProjectManager = "Project Manager",
  ProjectOwner = "Project Owner"
}

export class UserDto {
  /**
   * User Object ID from Azure AD
   */
  userId: string = uuidv4(); // This will be a GUID

  /**
   * First Name of the User
   */
  firstName: string = '';

  /**
   * Last Name of the User
   */
  lastName: string = '';

  /**
   * Email of the User
   */
  email: string = '';

  /**
   * User Full Name
   */
  get displayName(): string {
    return `${this.firstName} ${this.lastName}`;
  }

  constructor(partial?: Partial<UserDto>) {
    Object.assign(this, partial);
  }
}

export enum UserRoles {
  Administrator = "Administrators",
  Operations = "Operations",
}

export class UserMeDto {
  userId: string = '';
  entraId: string = '';
  email: string = '';
  firstName: string = '';
  lastName: string = '';
  roles: string[] = [];
  //isAdministrator: boolean = false;
  //isOperations: boolean = false;
  //projects: { [key: string]: ProjectPermissionLevel } = {};
  memberInProjects: string[] = [];

  get privilegedUser(): boolean {
    return this.roles.includes(UserRoles.Administrator) || this.roles.includes(UserRoles.Operations);
  }

  constructor(partial?: Partial<UserMeDto>) {
    Object.assign(this, partial);
  }
}



